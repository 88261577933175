import { Link as RemixLink } from "@remix-run/react";

import { Link as MuiLink } from "@mui/material";

export default (
  href: string,
): { href: string } | { to: string; component: typeof RemixLink } =>
  href.startsWith("https://") ||
  href.startsWith("mailto:") ||
  href.startsWith("/pdf") ||
  href.startsWith("http://")
    ? { href, component: MuiLink as never }
    : { to: href, component: RemixLink };
